<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />
         <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">         
            <template v-for="(item, key) in list_check_box">               
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                      <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->
                    
                    
                    <!-- Section Detail Start -->
                    <!--information_general start-->
                    <InformationGeneral v-if="key == 'detail_information_general' && item.show" />
                    <!--information_general end-->

                    <!--detail_indication start-->
                    <Indication v-else-if="key == 'detail_indication' && item.show" /> 
                    <!--detail_indication end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show" />
                    <!-- treatment_line end -->

                    <!-- detail Submission to AOTM -->
                    <table class="comparateur2 text-left" v-if="key == 'detail_Submission' && item.show">
                        <tr v-if="data.aotmit['submission_to_aotm_date']">
                            <th width="35%">Date</th>
                            <td>{{DDMMMYY(data.aotmit['submission_to_aotm_date'])}}</td>
                        </tr>
                        <tr v-if="data.aotmit['submission_to_aotm_ref']">
                            <th width="35%">Reference </th>
                            <td>{{data.aotmit['submission_to_aotm_ref']}}</td>
                        </tr>
                    </table>


                    <!-- detail conclusion_aotmit -->
                    <div class="content-onglets" v-else-if="key == 'detail_conclusion_aotmit' && item.show">
                        <TinyHTML :html="data.aotmit['conclusion_en']"/>
                    </div>

                    <!--Conclusion start-->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />
                    <!--Conclusion end-->

                    <!-- detail Rationale / Justification of the decision -->
                    <div class="content-onglets" v-else-if="key == 'detail_Rationale' && item.show">
                        <TinyHTML :html="data.aotmit['rationale_and_commentary_en']"/>
                    </div>

                    <!-- detail Efficacy -->
                    <div class="content-onglets" v-else-if="key == 'detail_Efficacy' && item.show">
                        <TinyHTML :html="data.aotmit['efficacy']"/>
                    </div>

                    <!-- detail Safety -->
                    <div class="content-onglets" v-else-if="key == 'detail_Safety' && item.show">
                        <TinyHTML :html="data.aotmit['safety']"/>
                    </div>

                    <!-- detail Cost-effectiveness -->
                    <div class="content-onglets" v-else-if="key == 'detail_Cost_effectiveness' && item.show">
                        <TinyHTML :html="data.aotmit['cost_effectiveness']"/>
                    </div>

                    <!-- detail Other -->
                    <div class="content-onglets" v-else-if="key == 'detail_Other' && item.show">
                        <TinyHTML :html="data.aotmit['other']"/> 
                    </div>

                    <!-- detail_key_documents start -->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>
                    <!-- detail_key_documents end -->

                    <!--EssaisClinique start-->
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                    <!--EssaisClinique end-->   

                </div>
            </template>
            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue';
import TreatmentLine from '../treatment_line.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import LinkAgency from '../LinkAgency.vue'
import KeyDocument from '../key_document.vue'
import Indication from '../Indication.vue'
import InformationGeneral from '../InformationGeneral.vue'
import Conclusion from '../conclusion.vue'
import { DDMMMYY, check_detail_conclusion, key_documents } from '../../../utils'
import TinyHTML from '../tinymce_html.vue'

export default{
    name: 'aotmit',
    components : {
        LeftSection,
        TreatmentLine,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        InformationGeneral,
        Indication,
        Conclusion,
        TinyHTML
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_information_general :  {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_indication : { 'title' : 'indication', 'show' : true, 'enable' : false, 'orange_text':false},               
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Submission : {'title' : 'Submission to AOTM', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_conclusion_aotmit : {'title' : 'Conclusion', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Conclusion : {'title' : 'Conclusion', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_Rationale : {'title' : 'Rationale / Justification of the decision', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Efficacy : {'title' : 'Efficacy', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Safety : {'title' : 'Safety', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Cost_effectiveness : {'title' : 'Cost-effectiveness', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Other : {'title' : 'Other', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },             
            },
        }
    },
    created(){

        // condition to show check box in left section [enable]
        if(this.data && this.data.aotmit){
            
            if(this.data['ma_date']){ 
                this.list_check_box['detail_information_general'].enable = true
            }
            if(this.data['indication_en']){ 
                this.list_check_box['detail_indication'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }

            if(this.data.aotmit['submission_to_aotm_date'] || this.data.aotmit['submission_to_aotm_ref'])  { 
                this.list_check_box['detail_Submission'].enable = true
            }
            if(this.data.aotmit['conclusion_en'])
            { 
                this.list_check_box['detail_conclusion_aotmit'].enable = true
            }
            if(check_detail_conclusion(this.data))
            { 
                this.list_check_box['detail_Conclusion'].enable = true
            }
            if(this.data.aotmit['rationale_and_commentary_en'])
            { 
                this.list_check_box['detail_Rationale'].enable = true
            }
            if(this.data.aotmit['efficacy'])
            { 
                this.list_check_box['detail_Efficacy'].enable = true
            }
            if(this.data.aotmit['safety'])
            { 
                this.list_check_box['detail_Safety'].enable = true
            }
            if(this.data.aotmit['cost_effectiveness'])
            { 
                this.list_check_box['detail_Cost_effectiveness'].enable = true
            }
            if(this.data.aotmit['other'])
            { 
                this.list_check_box['detail_Other'].enable = true
            }
            
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }                
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }

        }
    },
    methods : {
        DDMMMYY,
        check_detail_conclusion,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }   
    },
}

</script>

<style>

</style>